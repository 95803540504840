import React from 'react'
import { bool, string } from 'prop-types'
import classNames from 'classnames'

import styles from './FormNotification.module.scss'

const FormNotification = ({ statement, singleLine = false, isSuccess }) => {
  const defaultHeadline = 'There was a problem with your submission.'
  const headline = singleLine ? statement : defaultHeadline
  return (
    <div
      className={classNames(
        isSuccess ? styles.Success : styles.Error,
        styles.Notification
      )}
    >
      <h2 className={classNames(styles.Text, styles.Headline)}>{headline}</h2>
      {!singleLine && (
        <p className={classNames(styles.Text, styles.Subtitle)}>{statement}</p>
      )}
    </div>
  )
}

FormNotification.propTypes = {
  statement: string,
  singleLine: bool,
  isSuccess: bool
}

export default FormNotification
